<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>DASHBOARD</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="profile-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                        <div class="profile-edit">
                            <h2><i class="fa fa-cogs"></i> PROFILE SETTINGS</h2>
                            <form class="about-me-profile" enctype="multipart/form-data">
                                <div class="upload-img">
                                    <div class="row middel-flex">
                                        <div class="col-sm-4 imgUp">
                                            <!-- <img :src="imgsrc+user.profile_img"> -->

                                            <div
                                                class="imagePreview"
                                                v-bind:style="{
                                                    backgroundImage:
                                                        'url(' + imgsrc + user.profile_img + ')',
                                                }"
                                            ></div>
                                            <label
                                                class="btn btn-primary"
                                                style="display:block;border-radius:0px;box-shadow:0px 4px 6px 2px rgba(0,0,0,0.2);margin-top:-5px;"
                                            >
                                                Upload<input
                                                    type="file"
                                                    class="uploadFile img"
                                                    value="Upload Photo"
                                                    name="image"
                                                    @change="previewFiles"
                                                    style="width: 0px;height: 0px;overflow: hidden;padding: 0px 0px;"
                                                />
                                            </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <!-- <input type="file" class="btn btn-primary" style="border-radius: 25px;background-color: #8c0526;overflow: hidden;" name="image" multiple @change="previewFiles"> -->

                                            <!--<a href="#"><i class="fa fa-camera"></i> UPLOAD IMAGE</a>-->

                                            <p>
                                                Update your avatar manually, If not set, the default
                                                Gravatar will be the same as your login email/user
                                                account.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <template v-if="!isMaterialTester">
                                    <h3>My PLAN</h3>

                                    <div v-if="currentPlan" class="p-2">
                                        <div
                                            class="d-inline-block bg-primary text-white px-3 py-2 mb-2"
                                        >
                                            {{ currentPlan.plan.plans_name }}
                                        </div>
                                        <div class="d-inline-block mx-2" v-if="!isFreePlan">
                                            {{ currentPlan.days_left }} days left
                                        </div>
                                        <button
                                            type="button"
                                            class="primary-button"
                                            @click="$router.push({ name: 'pricing' })"
                                        >
                                            View or Upgrade My Plan
                                        </button>
                                    </div>

                                    <div v-else style="text-align:center;">
                                        <span v-if="!isMaterialTester">No Plans Selected</span>
                                    </div>
                                </template>

                                <h3>ABOUT ME</h3>

                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="tagline"
                                        placeholder="Enter Tagline"
                                        v-model="tagline"
                                        class="form-control"
                                    />
                                    <div class="status" id="tagline-status"></div>
                                </div>
                                <div class="form-group">
                                    <textarea
                                        placeholder="About Yourself"
                                        name="yourself"
                                        rows="5"
                                        v-model="yourself"
                                        class="form-control"
                                    ></textarea>
                                    <div class="status" id="yourself-status"></div>
                                </div>

                                <!--<select name="expertize" v-model="expertize">-->
                                <!--   <option disabled value="" >Area of Inspection Expertize</option>-->
                                <!--   <option value="1">Area of Inspection Expertize</option>-->
                                <!--</select>-->
                                <div class="status" id="expertize-status"></div>

                                <h3>CONTACT DETAILS</h3>
                                <div class="form-group row">
                                    <div class="col-6 mb-3">
                                        <input
                                            type="text"
                                            name="mobile1"
                                            placeholder="Phone Number"
                                            v-model="mobile1"
                                            class="form-control "
                                        />
                                    </div>
                                    <div class="col-6 mb-3">
                                        <span class="status" id="mobile-status"></span>
                                        <input
                                            type="text"
                                            name="mobile"
                                            placeholder="Mobile Numver"
                                            v-model="user.mobile"
                                            disabled
                                            class="form-control"
                                        />
                                    </div>

                                    <div class="col-6">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email Address"
                                            v-model="user.email"
                                            disabled
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-6">
                                        <input
                                            type="text"
                                            name="website"
                                            placeholder="Website"
                                            v-model="website"
                                            class="form-control "
                                        />
                                    </div>

                                    <div class="status" id="website-status"></div>
                                </div>
                                <h3>UPLOAD CERTIFICATES IF ANY</h3>
                                <div class="upload-img">
                                    <div class="row middel-flex">
                                        <div class="col-sm-4 imgUp">
                                            <!-- <img :src="imgsrc+user.certificat_img"> -->

                                            <div
                                                class="imagePreview"
                                                v-bind:style="{
                                                    backgroundImage:
                                                        'url(' + imgsrc + user.certificat_img + ')',
                                                }"
                                            ></div>
                                            <label
                                                class="btn btn-primary"
                                                style="display:block;border-radius:0px;box-shadow:0px 4px 6px 2px rgba(0,0,0,0.2);margin-top:-5px;"
                                            >
                                                Upload<input
                                                    type="file"
                                                    class="uploadFile img"
                                                    value="Upload Photo"
                                                    name="certificates"
                                                    @change="previewFiless"
                                                    style="width: 0px;height: 0px;overflow: hidden;padding: 0px 0px;"
                                                />
                                            </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <!-- <input type="file" class="btn btn-primary" style="border-radius: 25px;background-color: #8c0526;overflow: hidden;" name="certificates" multiple @change="previewFiless"> -->

                                            <!--<a href="#"><i class="fa fa-camera"></i> UPLOAD IMAGE</a>-->
                                        </div>
                                    </div>
                                </div>
                                <h3>PASSWORD UPDATE</h3>
                                <!--<input type="text" name="old" placeholder="type current password" v-model="old">
                        <div v-if="ress && ress.old" class="text-danger">{{ ress.old[0] }}</div>-->

                                <div class="form-group row">
                                    <div class="col-6">
                                        <input
                                            class="form-control"
                                            type="password"
                                            v-model="password"
                                            placeholder="type new password"
                                        />
                                    </div>
                                    <div v-if="ress && ress.password" class="text-danger">
                                        {{ ress.password[0] }}
                                    </div>

                                    <div class="col-6">
                                        <input
                                            type="password"
                                            name="password_confirmation"
                                            placeholder="re-type new password"
                                            v-model="password_confirmation"
                                            class="form-control"
                                        />

                                        <div
                                            v-if="ress && ress.password_confirmation"
                                            class="text-danger"
                                        >
                                            {{ ress.password_confirmation[0] }}
                                        </div>
                                    </div>
                                </div>

                                <transition name="hint" appear>
                                    <div
                                        v-if="passwordValidation.errors.length > 0 && !submitted"
                                        class="hints"
                                    >
                                        <h2>Hints</h2>
                                        <p v-for="(error, i) in passwordValidation.errors" :key="i">
                                            {{ error }}
                                        </p>
                                    </div>
                                </transition>

                                <button type="submit" class="primary-button" @click="update">
                                    UPDATE NOW
                                </button>
                            </form>
                        </div>
                    </div>
                    <LeftBar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import LeftBar from "../components/leftbar.vue"
import request from "../apis/request"
import { mapState, mapGetters, mapActions } from "vuex"
import scrollTop from "./utils/scrollTop"

export default {
    name: "dashboard",

    components: {
        LeftBar,
    },

    data() {
        return {
            rules: [
                { message: "One lowercase letter required.", regex: /[a-z]+/ },
                { message: "One uppercase letter required.", regex: /[A-Z]+/ },
                { message: "8 characters minimum.", regex: /.{8,}/ },
                { message: "One number required.", regex: /[0-9]+/ },
            ],

            plans: "",
            product: "",
            enquire_count: "",
            submitted: false,

            ress: {},
            //email: "",
            //mobile: "",
            tagline: "",
            yourself: "",
            expertize: "",
            mobile1: "",
            website: "",
            old: "",
            password: "",
            password_confirmation: "",
            image: "",
            certificates: "",
            jobs_count: "",

            action: "/buyerupdate",

            imgsrc: axios.defaults.uploadURL,
        }
    },

    methods: {
        ...mapActions("auth", {
            fetchUser: "fetchUser",
            logoutUser: "logout",
        }),
        updateData() {
            if (this.user) {
                const { yourself, tagline, website, expertize, phone_number } = this.user
                this.tagline = tagline
                this.yourself = yourself
                this.website = website
                this.expertize = expertize
                this.mobile1 = phone_number
            }
        },
        async logout() {
            //  HTTP.post("api/logout").then(()=>{
            //   localStorage.removeItem('isLogged');
            //   window.localStorage.removeItem('isLogged');
            //   window.localStorage.removeItem('user');
            //   this.isLogged = false;
            //  })
            await this.logoutUser()
            // this.$router.push({ name: "landing" })
            window.location.reload()
        },

        previewFiles(event) {
            console.log(event)
            console.log(event.target.files[0])

            this.image = event.target.files[0]
            // console.log(this.image)
        },

        previewFiless(event) {
            console.log(event)
            //console.log(event.target.files[0]);

            this.certificates = event.target.files[0]
        },

        update: function(e) {
            e.preventDefault()

            this.ress = {}

            const form = new FormData()
            form.append("tagline", this.tagline)
            form.append("yourself", this.yourself)
            form.append("expertize", this.expertize)
            form.append("mobile1", this.mobile1)
            form.append("website", this.website)
            // form.append('old',this.old);
            form.append("password", this.password)
            form.append("password_confirmation", this.password_confirmation)
            form.append("image", this.image)
            form.append("certificates", this.certificates)

            //console.log(this.image);

            request
                .post(this.action, form)
                .then((res) => {
                    this.$toasted.success("Profile Updated in successfully", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })
                    this.fetchUser()

                    //   alert('Profile Update successfully!');
                })
                .catch((res) => {
                    //alert('err');
                    if (res.response.status === 422) {
                        this.ress = res.response.data.errors || {}
                    }
                    return res
                })
        },

        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatemobile1: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile1.length; i++) {
                if (!(this.mobile1[i] >= "0" && this.mobile1[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("mobile-status").innerHTML = msg
            return res && digitCount == 10
        },
    },

    mounted() {
        console.log("image upload url", this.imgsrc)
        scrollTop()
        this.updateData()

        request.get("/product-count").then((res) => {
            this.product = res.data.product_count
            this.enquire_count = res.data.enquire_count
            this.jobs_count = res.data.jobs_count
            console.log("PRODUCT COUNT", res)
        })

        request.get("/selectlans").then((res) => {
            this.plans = res.data
        })
    },

    computed: {
        ...mapState("auth", {
            user: "user",
            isLogged: "authenticated",
            authenticated: "authenticated",
        }),
        ...mapState("plan", {
            currentPlan: "currentPlan",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
        ...mapGetters("plan", {
            isFreePlan: "isFreePlan",
        }),
        notSamePasswords() {
            if (this.passwordsFilled) {
                return this.password !== this.checkPassword
            } else {
                return false
            }
        },
        passwordsFilled() {
            return this.password !== "" && this.checkPassword !== ""
        },
        passwordValidation() {
            let errors = []
            for (let condition of this.rules) {
                if (!condition.regex.test(this.password)) {
                    errors.push(condition.message)
                }
            }
            if (errors.length === 0) {
                return { valid: true, errors }
            } else {
                return { valid: false, errors }
            }
        },
    },
}
</script>

<style lang="scss">
.submit_button {
    padding: 12px 35px;
    width: auto;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    color: #fff;
    background-color: #8c0526;
    text-align: center;
    margin: 30px auto;
    display: block;
}
/*body{
  background-color:#f5f5f5;
}*/
.imagePreview {
    width: 100%;
    height: 180px;
    background-position: center center;
    background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}
/*.btn-primary
{
  display:block;
  border-radius:0px;
  box-shadow:0px 4px 6px 2px rgba(0,0,0,0.2);
  margin-top:-5px;
}*/
.imgUp {
    margin-bottom: 15px;
}
/*.del
{
  position:absolute;
  top:0px;
  right:15px;
  width:30px;
  height:30px;
  text-align:center;
  line-height:30px;
  background-color:rgba(255,255,255,0.6);
  cursor:pointer;
}*/
/*.imgAdd
{
  width:30px;
  height:30px;
  border-radius:50%;
  background-color:#4bd7ef;
  color:#fff;
  box-shadow:0px 0px 2px 1px rgba(0,0,0,0.2);
  text-align:center;
  line-height:30px;
  margin-top:0px;
  cursor:pointer;
  font-size:15px;
}*/

.hint {
    &-enter {
        opacity: 0;
        transform: translate3d(-20px, 0, 0);
    }
    &-leave-to {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }
    &-enter-active {
        transition: 300ms;
    }
    &-leave-active {
        transition: 400ms;
    }
}

.hints {
    max-width: 400px;
    padding: 1em;
    background: whitesmoke;
    margin: 1em 0;
    font-size: 0.9em;
    color: darken(#d4dedf, 50%);
    h2 {
        margin: 0.5em 0 0.2em 0;
    }
    p {
        margin: 0;
        padding-left: 1em;
        &::before {
            content: ">";
            font-size: 0.9em;
            margin-right: 6px;
            display: inline-block;
        }
    }
}
</style>
